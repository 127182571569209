import Vue from "vue";
import Vuex from "vuex";
import themer from "@xtreme-vue-utils/themer";
import authentication from "@/store/modules/authentication";
import { genUuid } from "@/mixins/generateUuid";
import _ from "lodash";

Vue.use(Vuex);

function getDefaultState() {
    return {
        routeHistory: [],
        warehouses: [],
        warehousesLoaded: false,
        customers: [],
        customersLoaded: false,
        users: [],
        usersLoaded: false,
        showUnsavedChangesDialog: false,
        nextNav: () => {}, // used by unsavedChanges dialog
        accessLevels: {
            0: "Super Admin",
            1: "Admin",
            2: "Operative",
            3: "Customer",
            4: "Temporary User",
            9: "Unauthorised New User",
            11: "Guest (Automatic)",
            12: "Guest (Signed Up)",
        },
    };
}

const debounceDelay = 200;

export const storeModel = {
    state: getDefaultState(),
    mutations: {
        pushRouteToHistory(state, route) {
            state.routeHistory.push(route);
        },
        addCustomer(state, customerData) {
            state.customers.push(_.cloneDeep(customerData));
        },
        addUser(state, userData) {
            state.users.push(_.cloneDeep(userData));
        },
        addWarehouse(state, warehouseData) {
            state.warehouses.push(_.cloneDeep(warehouseData));
        },
        updateCustomer(state, customerData) {
            const index = state.customers.findIndex((c) => c.id === customerData.id);
            Vue.set(state.customers, index, _.cloneDeep(customerData));
        },
        updateUser(state, userData) {
            const index = state.users.findIndex((u) => u.uid === userData.uid);
            Vue.set(state.users, index, _.cloneDeep(userData));
        },
        updateWarehouse(state, warehouseData) {
            const index = state.warehouses.findIndex((w) => w.id === warehouseData.id);
            Vue.set(state.warehouses, index, _.cloneDeep(warehouseData));
        },
        updateCustomers(state, customersData) {
            let custData = customersData;
            const { user } = state.authentication.xAuth;
            // TODO: Remove bodge when properly implemented on backend
            if (user.accessLevel === 3) {
                custData = customersData.filter((c) => c.id === user.info.customerId);
            }
            state.customers = _.cloneDeep(custData);
            state.customersLoaded = true;
        },
        updateUsers(state, usersData) {
            state.users = _.cloneDeep(usersData);
            state.usersLoaded = true;
        },
        updateWarehouses(state, warehousesData) {
            let whData = warehousesData;
            const { user } = state.authentication.xAuth;
            // TODO: Remove bodge when properly implemented on backend
            if (user.accessLevel === 3) {
                whData = warehousesData.filter((w) => w.customerId === user.info.customerId);
            }
            state.warehouses = _.cloneDeep(whData);
            state.warehousesLoaded = true;
        },
        removeCustomer(state, customerData) {
            state.customers = state.customers.filter((c) => c.id !== customerData.id);
        },
        removeWarehouse(state, warehouseData) {
            state.warehouses = state.warehouses.filter((w) => w.id !== warehouseData.id);
        },
        removeUser(state, userData) {
            state.users = state.users.filter((u) => u.uid !== userData.uid);
        },
        resetState(state) {
            Object.assign(state, getDefaultState());
        },
        updateShowUnsavedChangesDialog(state, newState) {
            state.showUnsavedChangesDialog = newState;
        },
        updateNextNav(state, next) {
            state.nextNav = next;
        },
    },
    actions: {
        subscribeToCustomers: _.debounce((context) => {
            if (context.state.customers.length) {
                return;
            }

            const accessLevel = context.getters["authentication/accessLevel"];
            if (accessLevel >= 4) {
                return;
            }

            context.dispatch("authentication/send", {
                type: "customers",
                action: "get",
                meta: {
                    organisation: "fluid",
                },
            });
        }, debounceDelay),

        subscribeToUsers: _.debounce((context) => {
            if (context.state.customers.length) {
                return;
            }

            const accessLevel = context.getters["authentication/accessLevel"];
            if (accessLevel >= 4) {
                return;
            }

            context.dispatch("authentication/send", {
                type: "users",
                action: "get",
                meta: {
                    organisation: "fluid",
                    location: "*",
                },
            });
        }, debounceDelay),

        subscribeToWarehouses: _.debounce((context) => {
            if (context.state.warehouses.length) {
                return;
            }

            const accessLevel = context.getters["authentication/accessLevel"];
            if (accessLevel >= 4) {
                return;
            }

            context.dispatch("authentication/send", {
                type: "warehouses",
                action: "get",
                meta: {
                    organisation: "fluid",
                },
            });
        }, debounceDelay),

        addCustomer(context, customerData) {
            context.commit("addCustomer", customerData);
            context.dispatch("authentication/send", {
                meta: {
                    organisation: "fluid",
                },
                type: "customers",
                action: "create",
                data: [
                    customerData,
                ],
            });
        },

        addItems(context, itemsData) {
            const customerData = _.cloneDeep(context.getters.customer(itemsData[0].customerId));

            itemsData.forEach((itemData) => {
                customerData.items.push(_.cloneDeep(itemData));
            });

            context.dispatch("updateCustomer", customerData);
        },

        addItem(context, itemData) {
            context.dispatch("addItems", [itemData]);
        },

        addJob(context, { jobData, newItems = [] }) {
            const customerData = _.cloneDeep(context.getters.customer(jobData.customerId));

            customerData.jobs.push(_.cloneDeep(jobData));
            customerData.items.push(...newItems);

            context.dispatch("updateCustomer", customerData);
        },

        addUser(context, userData) {
            context.commit("addUser", userData);
            context.dispatch("authentication/send", {
                meta: {
                    organisation: "fluid",
                },
                type: "users",
                action: "create",
                data: [
                    userData,
                ],
            });
        },

        addWarehouse(context, warehouseData) {
            const optimisticUpdate = _.cloneDeep(warehouseData);
            optimisticUpdate.id = genUuid("WH");
            context.commit("addWarehouse", optimisticUpdate);
            context.dispatch("authentication/send", {
                meta: {
                    organisation: "fluid",
                },
                type: "warehouses",
                action: "create",
                data: [
                    optimisticUpdate,
                ],
            });
        },

        updateCustomer(context, customerData) {
            context.commit("updateCustomer", customerData);
            context.dispatch("authentication/send", {
                meta: {
                    organisation: "fluid",
                },
                type: "customers",
                action: "update",
                data: [
                    customerData,
                ],
            });
        },

        updateItem(context, itemData) {
            const customerData = _.cloneDeep(context.getters.customer(itemData.customerId));

            const itemIndex = customerData.items.findIndex((i) => i.id === itemData.id);
            customerData.items[itemIndex] = _.cloneDeep(itemData);

            context.dispatch("updateCustomer", customerData);
        },

        updateJob(context, { jobData, newItems = [] }) {
            const customerData = _.cloneDeep(context.getters.customer(jobData.customerId));

            const jobIndex = customerData.jobs.findIndex((j) => j.id === jobData.id);
            customerData.jobs[jobIndex] = _.cloneDeep(jobData);
            customerData.items.push(...newItems);

            context.dispatch("updateCustomer", customerData);
        },

        updateUser(context, userData) {
            context.commit("updateUser", userData);
            context.dispatch("authentication/send", {
                meta: {
                    organisation: "fluid",
                },
                type: "users",
                action: "update",
                data: [
                    userData,
                ],
            });
        },

        updateWarehouse(context, warehouseData) {
            context.commit("updateWarehouse", warehouseData);
            context.dispatch("authentication/send", {
                meta: {
                    organisation: "fluid",
                },
                type: "warehouses",
                action: "update",
                data: [
                    warehouseData,
                ],
            });
        },

        removeUser(context, userData) {
            context.commit("removeUser", userData);
            context.dispatch("authentication/send", {
                meta: {
                    organisation: "fluid",
                },
                type: "users",
                action: "delete",
                data: [
                    userData,
                ],
            });
        },

        removeWarehouse(context, warehouseData) {
            context.commit("removeWarehouse", warehouseData);
            context.dispatch("authentication/send", {
                meta: {
                    organisation: "fluid",
                },
                type: "warehouses",
                action: "delete",
                data: [
                    warehouseData,
                ],
            });
        },

        removeCustomer(context, customerData) {
            context.commit("removeCustomer", customerData);
            context.dispatch("authentication/send", {
                meta: {
                    organisation: "fluid",
                },
                type: "customers",
                action: "delete",
                data: [
                    customerData,
                ],
            });
        },

        unSubscribeToUsers(context) {
            context.dispatch("authentication/send", {
                type: "users",
                action: "unsubscribe",
                meta: {
                    organisation: "fluid",
                    location: "*",
                },
            });
        },

        triggerNotification(context, notification) {
            context.dispatch("authentication/send", {
                meta: {
                    organisation: "fluid",
                },
                type: "notifications",
                action: "trigger",
                data: [
                    notification,
                ],
            });
        },

        continueNav(context) {
            context.commit("updateShowUnsavedChangesDialog", false);
            context.state.nextNav();
        },
    },
    getters: {
        // Notes for anyone who is not Eugene:
        // Vue getters return computed values from the store.
        // Getters without a param are cached, getters with params are not!
        // The `(state) =>` is a wrapping function that just makes state available to the getter.
        // When returning an object remember to add a default using `|| {}` incase data has not yet loaded from backend.
        // Docs: https://vuex.vuejs.org/guide/getters.html

        // warehouse(warehouseId) returns specified warehouse
        warehouse: (state) => (warehouseId) => state.warehouses.find((w) => w.id === warehouseId) || {},

        // customer(customerId) returns specified customer
        customer: (state) => (customerId) => state.customers.find((c) => c.id === customerId) || {},

        // user(userId) returns specified user
        user: (state) => (userId) => state.users.find((u) => u.uid === userId) || {},

        // location(locationId) searches all customers and return specified location
        location: (state) => (locationId) => state.customers
            .reduce((acc, customer) => [...acc, ...customer.locations], [])
            .find((l) => l.id === locationId) || {},

        // items returns all items from all customers
        items: (state) => state.customers
            .reduce((acc, customer) => [...acc, ...customer.items], []),

        // item(itemId) searches all customers and return specified item
        item: (state) => (itemId) => state.customers
            .reduce((acc, customer) => [...acc, ...customer.items], [])
            .find((i) => i.id === itemId) || {},

        // jobs returns all jobs from all customers
        jobs: (state) => state.customers
            .reduce((acc, customer) => [...acc, ...customer.jobs], []),

        // job(jobId) searches all customers and return specified job
        job: (state) => (jobId) => state.customers
            .reduce((acc, customer) => [...acc, ...customer.jobs], [])
            .find((j) => j.id === jobId) || {},

        // issues returns all issues from all customers
        issues: (state) => state.customers
            .reduce((acc, customer) => [...acc, ...customer.issues], []),

        // issue(issueId) searches all customers and return specified issue
        issue: (state) => (issueId) => state.customers
            .reduce((acc, customer) => [...acc, ...customer.issues], [])
            .find((i) => i.id === issueId) || {},
    },
    modules: {
        authentication,
        themer,
    },
};

export default new Vuex.Store(storeModel);
