<template>
    <div class="text-center">
        <v-dialog
            width="500"
            color="primary"
            :value="value"
            data-test="save-before-leaving-dialog"
            @input="$emit('input', $event)"
        >
            <v-card>
                <v-card-title class="primary">
                    Are you sure?
                </v-card-title>
                <v-card-text class="mt-2">
                    You have unsaved changes. Are you sure you want to leave?
                </v-card-text>
                <v-card-actions class="d-flex justify-end">
                    <v-btn
                        color="grey"
                        text
                        data-test="cancel-btn"
                        @click="close"
                    >
                        Cancel
                    </v-btn>
                    <v-btn
                        color="secondary text-primary"
                        data-test="continue-btn"
                        @click="continueNav"
                    >
                        Continue
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
export default {
    name: "SaveBeforeLeavingDialog",
    props: {
        value: {
            type: Boolean,
            default: false,
        },
    },
    methods: {
        close() {
            this.$emit("input", false);
        },
        continueNav() {
            this.$emit("input", false);
            this.$emit("continueNav");
        },
    },
};
</script>
