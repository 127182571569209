/* istanbul ignore file */

// Route object:
// hideNavigation (boolean): default is false, true will hide entire navigation menu on this route.
// requireAuth (boolean): default is false, true means you must be logged in to access.
// accessLevel (object): will hide item from menu and display 401 access denied if you try to access.
// menu (array): array of menu items, this is so that the routes can appear on the menu more than once or in different places/with a different name for different access levels.

// Menu object:
// If object exists it will be added to the side menu (filtered by access level)
// - icon (string): mdi icon name
// - text (string): text to appear on the link
// - group (string): if set, will group the menu item under the group name
// - accessLevel (object): if set, will only show the menu item if the user has the required access level

// Access level object:
// Note that because the highest access level is 0, gt: 2 would mean 0 or 1 (so less than, numerically speaking)
// - lt (number): less than
// - lte (number): less than or equal to
// - eq (number): equal to
// - neq (number): not equal to
// - gt (number):  greater than
// - gte (number): greater than or equal to

// Access level notes:
// Access level appears both at a route level and at a menu item level.
// The access level set on the route determines the access level required to actually access the route.
// The access level set on the menu item only determines who can see the link in the menu.
// Note that if you do not have access to a route it will not appear on your menu regardless of what you set on the menu item.
// If accessLevel has not been defined the route is presumed to be accessible by all users.

const routes = [
    {
        path: "/menu",
        alias: "/",
        name: "menu",
        component: () => import(/* webpackChunkName: "menu" */ "../views/MainMenu.vue"),
        meta: {
            requiresAuth: true,
            hideNavigation: true,
        },
    },
    {
        path: "/warehouses/list",
        name: "warehouses-list",
        component: () => import(/* webpackChunkName: "warehouses-list" */ "../views/warehouses/List.vue"),
        meta: {
            requiresAuth: true,
            accessLevel: { gte: 2 },
            menu: [
                {
                    icon: "mdi-warehouse",
                    text: "Warehouses",
                },
            ],
        },
    },
    {
        path: "/warehouses/new",
        name: "warehouse-new",
        component: () => import(/* webpackChunkName: "warehouses-warehouse" */ "../views/warehouses/Warehouse.vue"),
        meta: {
            requiresAuth: true,
            accessLevel: { gte: 2 },
        },
    },
    {
        path: "/warehouses/:warehouseId",
        name: "warehouses-edit",
        component: () => import(/* webpackChunkName: "warehouses-warehouse" */ "../views/warehouses/Warehouse.vue"),
        props: true,
        meta: {
            accessLevel: { gte: 2 },
            requiresAuth: true,
        },
    },
    {
        path: "/customers/list",
        name: "customers-list",
        component: () => import(/* webpackChunkName: "customers-list" */ "../views/customers/List.vue"),
        meta: {
            accessLevel: { gte: 2 },
            requiresAuth: true,
            menu: [
                {
                    icon: "mdi-domain",
                    text: "Customers",
                },
            ],
        },
    },
    {
        path: "/customers/new",
        name: "customers-new",
        component: () => import(/* webpackChunkName: "customers-customer" */ "../views/customers/Customer.vue"),
        meta: {
            accessLevel: { gte: 2 },
            requiresAuth: true,
        },
    },
    {
        path: "/customers/:customerId",
        name: "customers-edit",
        component: () => import(/* webpackChunkName: "customers-customer" */ "../views/customers/Customer.vue"),
        props: true,
        meta: {
            accessLevel: { gte: 2 },
            requiresAuth: true,
        },
    },
    {
        path: "/customers/:customerId/inventory",
        props: true,
        name: "customers-inventory",
        component: () => import(/* webpackChunkName: "customers-inventory" */ "../views/inventory/List.vue"),
        meta: {
            accessLevel: { gte: 3 },
            requiresAuth: true,
        },
    },
    {
        path: "/inventory/list",
        name: "inventory-list",
        component: () => import(/* webpackChunkName: "inventory-list" */ "../views/inventory/List.vue"),
        meta: {
            accessLevel: { gte: 3 },
            requiresAuth: true,
            menu: [
                {
                    icon: "mdi-package-variant-closed",
                    text: "Inventory",
                },
            ],
        },
    },
    {
        path: "/customers/:customerId/inventory/new",
        name: "inventory-new",
        component: () => import(/* webpackChunkName: "inventory-new" */ "../views/inventory/Item.vue"),
        props: true,
        meta: {
            accessLevel: { gte: 2 },
            requiresAuth: true,
        },
    },
    {
        path: "/customers/:customerId/inventory/import",
        name: "inventory-import",
        component: () => import(/* webpackChunkName: "inventory-import" */ "../views/inventory/Import.vue"),
        props: true,
        meta: {
            accessLevel: { gte: 2 },
            requiresAuth: true,
        },
    },
    {
        path: "/customers/:customerId/inventory/:itemId",
        name: "inventory-edit",
        component: () => import(/* webpackChunkName: "inventory-edit" */ "../views/inventory/Item.vue"),
        props: true,
        meta: {
            accessLevel: { gte: 3 },
            requiresAuth: true,
        },
    },
    {
        path: "/jobs/list",
        name: "jobs-list",
        component: () => import(/* webpackChunkName: "jobs-list" */ "../views/jobs/List.vue"),
        props: true,
        meta: {
            accessLevel: { gte: 3 },
            requiresAuth: true,
            menu: [
                {
                    icon: "mdi-clipboard-check-outline",
                    text: "Jobs",
                },
            ],
        },
    },
    {
        path: "/jobs/create",
        name: "jobs-create",
        component: () => import(/* webpackChunkName: "jobs-create" */ "../views/jobs/JobCreate.vue"),
        props: true,
        meta: {
            accessLevel: { gte: 3 },
            requiresAuth: true,
        },
    },
    {
        path: "/customers/:customerId/jobs/new",
        name: "jobs-details",
        component: () => import(/* webpackChunkName: "jobs-edit" */ "../views/jobs/JobDetails.vue"),
        props: true,
        meta: {
            accessLevel: { gte: 3 },
            requiresAuth: true,
        },
    },
    {
        path: "/customers/:customerId/jobs/:jobId",
        name: "jobs-edit",
        component: () => import(/* webpackChunkName: "jobs-edit" */ "../views/jobs/JobDetails.vue"),
        props: true,
        meta: {
            accessLevel: { gte: 3 },
            requiresAuth: true,
        },
    },
    {
        path: "/customers/:customerId/jobs/:jobId/loadinglist/",
        alias: "/customers/:customerId/jobs/:jobId/approval/",
        name: "loading-list",
        component: () => import(/* webpackChunkName: "loading-list" */ "../views/jobs/LoadingList.vue"),
        props: true,
        meta: {
            accessLevel: { gte: 3 },
            requiresAuth: true,
        },
    },
    {
        path: "/customers/:customerId/locations",
        name: "customers-locations",
        component: () => import(/* webpackChunkName: "customers-locations" */ "../views/customers/Locations.vue"),
        props: true,
        meta: {
            accessLevel: { gte: 3 },
            requiresAuth: true,
            menu: [
                {
                    icon: "mdi-office-building",
                    text: "Edit Locations",
                    group: "Admin",
                    accessLevel: { lte: 3 },
                },
            ],
        },
    },
    {
        path: "/customers/:customerId/users",
        name: "customers-users",
        component: () => import(/* webpackChunkName: "customers-users" */ "../views/customers/Users.vue"),
        props: true,
        meta: {
            accessLevel: { gte: 3 },
            requiresAuth: true,
            menu: [
                {
                    icon: "mdi-account-group",
                    text: "Edit Users",
                    group: "Admin",
                    accessLevel: { lte: 3 },
                },
            ],
        },
    },
    {
        path: "/users/list",
        name: "users-list",
        component: () => import(/* webpackChunkName: "users-list" */ "../views/users/List.vue"),
        meta: {
            accessLevel: { gte: 2 },
            requiresAuth: true,
            menu: [
                {
                    icon: "mdi-account-group",
                    text: "Users",
                    group: "Admin",
                },
            ],
        },
    },
    {
        path: "/users/profile",
        name: "users-profile",
        component: () => import(/* webpackChunkName: "users-profile" */ "../views/users/Profile.vue"),
        meta: {
            requiresAuth: true,
            menu: [
                {
                    icon: "mdi-account",
                    text: "Account Settings",
                    group: "Admin",
                },
            ],
        },
    },
    {
        path: "/users/profile/:uid?",
        name: "users-profile-edit",
        component: () => import(/* webpackChunkName: "users-profile-edit" */ "../views/users/Profile.vue"),
        meta: {
            accessLevel: { gte: 2 },
            requiresAuth: true,
        },
    },
    {
        path: "/login",
        name: "login",
        component: () => import(/* webpackChunkName: "login" */ "../views/Login.vue"),
        meta: {
            hideNavigation: true,
        },
    },
    {
        path: "/twofactorauth",
        name: "twofactorauth",
        component: () => import(/* webpackChunkName: "twofactorauth" */ "../views/TwoFactorAuth.vue"),
        props: true,
        meta: {
            hideNavigation: true,
        },
    },
    {
        path: "/registration",
        name: "registration",
        component: () => import(/* webpackChunkName: "registration" */ "../views/Registration.vue"),
        meta: {
            hideNavigation: true,
        },
    },
    {
        path: "/reset",
        name: "reset",
        component: () => import(/* webpackChunkName: "reset" */ "../views/Reset.vue"),
        meta: {
            hideNavigation: true,
        },
    },
    {
        path: "/401",
        name: "401",
        component: () => import(/* webpackChunkName: "401" */ "../views/401.vue"),
        meta: {},
    },
    {
        path: "/welcome",
        name: "welcome",
        component: () => import(/* webpackChunkName: "welcome" */ "../views/NewUserSplash.vue"),
        meta: {
            hideNavigation: true,
        },
    },
];

export default routes;
