<template>
    <v-navigation-drawer v-if="!hideNav" v-model="showNavMenu" class="bg-dark-background" data-test="nav-drawer" app>
        <v-list>
            <div v-for="(navItem, ni) in navItems" :key="ni">
                <div v-if="navItem.type === 'group'">
                    <v-list-item
                        link
                        :data-test="`nav-group-${ni}`"
                        @click="toggleGroup(navItem.text)"
                    >
                        <v-list-item-icon>
                            <v-icon>{{ openStates[navItem.text] ? "mdi-chevron-down" : "mdi-chevron-right" }}</v-icon>
                        </v-list-item-icon>
                        <v-list-item-content>
                            <v-list-item-title data-test="text" class="text-uppercase">{{ navItem.text }}</v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                    <v-list
                        v-if="openStates[navItem.text]"
                        class="sublist bg-background"
                    >
                        <v-list-item
                            v-for="(subNavItem, si) in navItem.items"
                            :key="si"
                            :to="getNavPath(subNavItem.route.path)"
                            :data-test="`sub-nav-item-${subNavItem.route.name}`"
                            link
                        >
                            <v-list-item-icon>
                                <v-icon>{{ subNavItem.icon }}</v-icon>
                            </v-list-item-icon>
                            <v-list-item-content>
                                <v-list-item-title data-test="text">
                                    {{ subNavItem.text }}
                                </v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                    </v-list>
                </div>
                <div v-else>
                    <v-list-item
                        :to="getNavPath(navItem.route.path)"
                        :data-test="`nav-item-${navItem.route.name}`"
                        link
                    >
                        <v-list-item-icon>
                            <v-icon>{{ navItem.icon }}</v-icon>
                        </v-list-item-icon>
                        <v-list-item-content>
                            <v-list-item-title data-test="text" class="text-uppercase">{{ navItem.text }}</v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                </div>
            </div>
        </v-list>
    </v-navigation-drawer>
</template>

<script>
import { hasRouteAccess } from "@xtreme-vue-utils/router";

export default {
    name: "NavDrawer",
    props: {
        xAuth: {
            type: Object,
            default() {
                return {};
            },
        },
        value: {
            type: Boolean,
            default: false,
        },
        routeProps: {
            type: Object,
            default() {
                return {};
            },
        },
    },
    data() {
        return {
            openStates: {},
            showNavMenu: this.value,
        };
    },
    computed: {
        navItems() {
            const userAccessLevel = this.xAuth.user?.accessLevel;

            // We are only interested in routes that have menu meta data and that we have access too.
            const routes = this.$router.getRoutes().filter((r) => {
                const hasMenuItems = r?.meta?.menu && r?.meta?.menu.length;
                const hasAccess = hasRouteAccess(r?.meta?.accessLevel, userAccessLevel);
                return hasMenuItems && hasAccess;
            });

            // Group routes by their group name.
            // Doing it this way menu item order should be preserved.
            const menuItems = [];
            routes.forEach((route) => {
                route.meta.menu.forEach((item) => {
                    const menuItem = { ...item, route };

                    // If it doesn't relate to our access level skip it.
                    if (!hasRouteAccess(menuItem.accessLevel, userAccessLevel)) {
                        return;
                    }

                    // If it's not part of a group just add it to the list.
                    if (!menuItem.group) {
                        menuItems.push(menuItem);
                        return;
                    }

                    // If it's part of an existing group add it to that group.
                    const group = menuItems.find((gr) => gr.type === "group" && gr.text === menuItem.group);
                    if (group) {
                        group.items.push(menuItem);
                        return;
                    }

                    // Otherwise create the group and add the route as the first item.
                    menuItems.push({
                        type: "group",
                        text: menuItem.group,
                        items: [menuItem],
                    });
                });
            });

            return menuItems;
        },
        hideNav() {
            const routeName = this.$route.name;
            const hideNav = this.$route.meta.hideNavigation;
            return hideNav === true || routeName === null;
        },
    },
    watch: {
        value(newValue) {
            this.showNavMenu = newValue;
        },
        showNavMenu(newValue) {
            this.$emit("input", newValue);
        },
    },
    created() {
        // Route won't be initialized at this point (or even by the time we get to the mounted hook) so we need to watch.
        const unwatch = this.$watch(() => this.$route, () => {
            this.openCurrentMenuItem();
            unwatch();
        });
    },
    methods: {
        toggleGroup(groupName) {
            const currentState = this.openStates[groupName];
            this.$set(this.openStates, groupName, !currentState);
        },
        openCurrentMenuItem() {
            this.navItems.forEach((menuItem) => {
                if (menuItem.type !== "group") {
                    return;
                }
                menuItem.items.forEach((item) => {
                    if (item.route.name === this.$route.name) {
                        this.$set(this.openStates, menuItem.text, true);
                    }
                });
            });
        },
        getNavPath(path) {
            const routePropKeys = Object.keys(this.routeProps);
            let pathString = path;
            if (routePropKeys.length) {
                routePropKeys.forEach((key) => {
                    pathString = pathString.replace(`:${key}`, this.routeProps[key]);
                });
            }
            return pathString;
        },
    },
};
</script>

<style>
.sublist {
    box-shadow: inset 0 0 10px rgba(255, 255, 255, 0.1) !important;
    padding: 0 !important;
}
.theme--light .sublist {
    box-shadow: inset 0 0 10px rgba(0, 0, 0, 0.15) !important;
}
</style>
