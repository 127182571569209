import { v4 as uuidv4 } from "uuid";

function genUuid(prefix) {
    return `${prefix}${prefix ? "-" : ""}${uuidv4().split("-").pop().toUpperCase()}`;
}

export { genUuid };

export default {
    methods: {
        generateUuid: genUuid,
    },
};
