import Vue from "vue";
import App from "@/App.vue";
import "@/registerServiceWorker";
import router from "@/router";
import store from "@/store";
import vuetify from "@/plugins/vuetify";
import xtremeVC from "@xtreme-controls/vue-components";
import VueQrcodeReader from "vue-qrcode-reader";

Vue.config.productionTip = false;

Vue.use(xtremeVC);
Vue.use(VueQrcodeReader);

new Vue({
    router,
    store,
    vuetify,
    render: (h) => h(App),
}).$mount("#app");
