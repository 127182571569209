<template>
    <v-app :style="themeStyles">
        <v-app-bar v-if="!hideNav" app>
            <v-app-bar-nav-icon data-test="app-bar-btn" @click.stop="showNavMenu = !showNavMenu" />
            <v-toolbar-title>Fluid WMS</v-toolbar-title>
            <v-spacer />
            <v-btn text data-test="btn-logout" @click="doLogout"> Logout </v-btn>
        </v-app-bar>
        <NavDrawer v-model="showNavMenu" :x-auth="xAuth" :route-props="routeProps" />
        <v-main>
            <router-view />
            <SaveBeforeLeavingDialog
                :value="showUnsavedChangesDialog"
                @input="updateShowUnsavedChangesDialog"
                @continueNav="continueNav"
            />
        </v-main>
        <x-update-service-worker snack-color="var(--colors-primary)" btn-color="var(--colors-secondary)" />
        <x-ybug id="gk3v5y5xbn2jbhs3547z" />
    </v-app>
</template>

<script>
import { mapActions, mapState, mapMutations, mapGetters } from "vuex";
import NavDrawer from "@/components/NavDrawer.vue";
import SaveBeforeLeavingDialog from "@/components/SaveBeforeLeavingDialog.vue";
import themes from "@/themes.json";

export default {
    components: {
        NavDrawer,
        SaveBeforeLeavingDialog,
    },
    data() {
        return {
            showNavMenu: !this.$vuetify.breakpoint.mobile,
        };
    },
    computed: {
        ...mapState("authentication", ["xAuth"]),
        ...mapState(["showUnsavedChangesDialog"]),
        ...mapGetters("themer", ["themeStyles"]),

        hideNav() {
            const routeName = this.$route.name;
            const hideNav = this.$route.meta.hideNavigation;
            return hideNav === true || routeName === null;
        },

        routeProps() {
            const customerId = this.xAuth?.user?.info?.customerId;
            if (customerId) {
                return { customerId };
            }

            return {};
        },
    },
    watch: {
        "xAuth.user": function (newVal) { // eslint-disable-line
            if (newVal === undefined) {
                this.resetState();
                return;
            }
            this.fetchAppData();
        },
    },
    created() {
        this.initialise();
        const { hostname } = window.location;
        // const hostname = "example.clix.com";
        // const hostname = "example-light.clix.com";

        const theme = themes.find((t) => t.domains?.includes(hostname));
        this.activateTheme({
            $vuetify: this.$vuetify,
            theme,
        });

        this.fetchAppData();
    },
    methods: {
        ...mapActions(["subscribeToWarehouses", "subscribeToUsers", "subscribeToCustomers", "continueNav"]),
        ...mapActions("themer", ["activateTheme"]),
        ...mapActions("authentication", ["initialise", "logout"]),
        ...mapMutations(["resetState", "updateShowUnsavedChangesDialog"]),

        async doLogout() {
            this.logout();
            await this.$router.push({ path: "/login" });
        },

        fetchAppData() {
            if (!this.xAuth?.user) {
                return;
            }

            this.subscribeToUsers();
            this.subscribeToWarehouses();
            this.subscribeToCustomers();
        },
    },
};
</script>

<style lang="scss">
#__ybug-launcher {
    top: 80% !important;
}

#app {
    background-color: var(--colors-background);
}

.v-app-bar {
    background-color: var(--colors-dark-background);
}

.firebase-emulator-warning {
    display: none;
}

@keyframes ybugSlideIn {
    0% {
        transform: translate3d(100%,-50%,0) rotate(270deg) translateY(-50%);
    }
    100% {
        transform: translate3d(50%,-50%,0) rotate(270deg) translateY(-50%);
    }
}

#__ybug-launcher {
    animation: ybugSlideIn 1s;
}

.v-application {
    .v-card.elevation-6 {
        box-shadow: none !important;
    }
    .v-sheet.v-card:not(.v-sheet--outlined) {
        box-shadow: none;
    }
    .v-card__actions {
        background-color: #FFFFFF;
    }
    .theme--light.v-app-bar.v-toolbar.v-sheet {
        background-color: #FFFFFF;
    }
    .controls-row {
        color: #FFFFFF !important;
        background-color: var(--colors-primary) !important;
    }
}

.text-primary {
    color: var(--colors-primary) !important;
}

.bg-primary {
    background: var(--colors-primary) !important;
}

.bg-background {
    background: var(--colors-background) !important;
}

.bg-dark-background {
    background: var(--colors-dark-background) !important;
}

.ws-no-wrap {
    white-space: nowrap;
}
</style>
