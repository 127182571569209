import Vue from "vue";
import VueRouter from "vue-router";
import { loginGuard, newUserGuard, accessGuard } from "@xtreme-vue-utils/router";
import store from "@/store";
import routes from "./routes";

store.dispatch("authentication/initialise");

Vue.use(VueRouter);

const router = new VueRouter({
    mode: "history",
    base: process.env.BASE_URL,
    routes,
});

const { xAuth } = store.state.authentication;

router.beforeEach(loginGuard(xAuth, "/login"));
router.beforeEach(newUserGuard(xAuth, "/welcome"));
router.beforeEach(accessGuard(xAuth, "/401"));

router.beforeEach((to, from, next) => {
    // Push the previous route to the custom history stack
    if (from.name) { // Avoid adding the initial route where `from.name` is null
        store.commit("pushRouteToHistory", from.path);
    }
    next();
});

export default router;
