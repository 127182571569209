import XAuth from "@xtreme-controls/xtreme-authentication";

export default {
    namespaced: true,
    state: () => ({
        xAuth: undefined,
    }),
    mutations: {
        updateXAuth(state, xAuth) {
            state.xAuth = xAuth;
        },
    },
    actions: {
        async login(context, loginPacket) {
            if (await context.state.xAuth.loggedIn()) {
                return true;
            }

            return context.state.xAuth.login(loginPacket.username, loginPacket.password);
        },

        async logout(context) {
            await context.state.xAuth.logout();
        },

        async send(context, msg) {
            if (!context.state.xAuth) {
                context.dispatch("initialise");
                await new Promise((resolve) => { setTimeout(resolve, 750); });
            }
            return context.state.xAuth.send(msg);
        },

        receivedFeedback(context, msg) {
            if (msg.action) {
                // Ignore packet echos
                return;
            }

            switch (msg.type) {
                case "warehouses":
                    context.commit("updateWarehouses", msg.data, { root: true });
                    break;
                case "customers":
                    context.commit("updateCustomers", msg.data, { root: true });
                    break;
                case "users":
                    context.commit("updateUsers", msg.data, { root: true });
                    break;
                default:
                    break;
            }
        },

        initialise(context) {
            if (!context.state.xAuth) {
                try {
                    let wsUrl = `${window.location.hostname}/ws/app/`;
                    const wsServerUrl = "$VUE_APP_WS_SERVER_URL";
                    if (process.env.VUE_APP_WS_SERVER_URL || wsServerUrl !== "$VUE_APP_WS_SERVER_URL") {
                        wsUrl = process.env.VUE_APP_WS_SERVER_URL || "$VUE_APP_WS_SERVER_URL";
                    }
                    const xAuthInstance = new XAuth(wsUrl, { requireSmsVerification: true }, (msg) => {
                        context.dispatch("receivedFeedback", msg);
                    });

                    context.commit("updateXAuth", xAuthInstance);
                } catch (err) {
                    console.error("xAuth Connection Error", err);
                }
            }
        },
    },
    getters: {
        accessLevel(state) {
            const unauthorisedNewUser = 9;
            const accessLevel = state.xAuth?.user?.info?.accessLevel;
            if (accessLevel === undefined) {
                return unauthorisedNewUser;
            }
            return accessLevel;
        },
        uid: (state) => state.xAuth?.user?.uid,
        user: (state) => state.xAuth?.user,
    },
};
