import Vue from "vue";
import Vuetify from "vuetify/lib/framework";
import { Ripple } from "vuetify/lib/directives";

Vue.use(Vuetify, {
    directives: { Ripple },
});

// Don't set theme colors here!! they are set in the themes.json in the root of the src directory for better theming support.
export default new Vuetify({});
